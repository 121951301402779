.mainContainer {
    background-color: #631618;
    height: 100%;
    overflow: hidden;
}

.container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100%;
    height: 200px; /* Adjust the height as needed */
    z-index: 9999; /* Adjust the z-index as needed */
}

.image {
    box-shadow: 0 0 10px black;
    transition: transform 0.3s ease;
    max-width: 100%;
    margin: 0 50px; /* Adjust the margins as needed */
}

.image:hover {
    transform: scale(1.05);
}


.container {
    justify-content: center;
}

.image {
    width: calc(18rem - 100px); /* Adjust the width as needed */
    margin: 0 50px; /* Adjust the margins as needed */
}

.centerImage {
    width: calc(20rem - 100px); /* Adjust the width as needed */
    margin: 0 50px; /* Adjust the margins as needed */
}

@media(max-width: 992px) {
    .image {
        width: calc(10rem) !important;
        margin: 0 10px !important;
    }

    .centerImage {
        width: calc(12rem) !important;
        margin: 0 10px !important;
    }
}

@media(max-width: 660px) {
    .image {
        width: calc(7rem) !important;
        margin: 0 1px !important;
    }

    .centerImage {
        width: calc(9rem) !important;
        margin: 0 1px !important;
    }
}

.bgImage {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.mainImageContainer {
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
    z-index: 100;
}

.mainImage {
    box-shadow: 0 0 10px black;
    width: 12rem;
    border-radius: 50%;
}

.mainTextContainer {
    box-shadow: 0 0 20px black;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1rem;
    font-family: Mulish;
}

.mainTextContainer div:first-child {
    font-family: Rockwell;
}

.headerBrasu {
    width: 100%;
    height: 2.5rem;
    position: absolute;
    background-color: #EF4423;
    bottom: 0%;
    left: 0;
    display: flex;
    flex-direction: row;
    z-index: 10000;
    align-items: center;
    color: #FFFFFF;
    font-family: Mulish;
    justify-content: space-around;
}

.rappiButton {
    width: 80%;
    background-color: #F16152;
    text-decoration: none;
    color: #FFFFFF;
    opacity: 100% !important;
    z-index: 1000;
    padding: 10px 20px;
    border-radius: 30px;
    text-transform: uppercase;
    box-shadow: 0 5px #DD3E31;
}

@media(max-width: 420px) {
    .rappiButton {
        padding: 10px 10px !important;
    }
}

.rappiButton:hover {
    background-color: #E24C3D;
    box-shadow: 0 5px #B03328;
    color: #E6E6E6;
}