.container {
    background-color: #EF4423;
    height: 100%;
}

.mainLogo {
    width: 15rem;
}

.optionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 3rem 15rem 0 15rem;
    height: 80%;
}

.option {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30%;
    box-shadow: 0 0 10px black;
    margin-right: 1rem;
    margin-left: 1rem;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

.optionInfoContainer {
    background-color: rgba(255, 255, 255, 0.8);
    width: 60%;
    height: 70%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.optionInfoContainer div:first-child {
    font-family: Rockwell;
}

.optionInfoContainer div:last-child {
    font-family: Mulish;
}

.rappiButton {
    margin-top: 3px;
    background-color: #F16152;
    text-decoration: none;
    color: #FFFFFF;
    opacity: 100% !important;
    z-index: 1000;
    padding: 10px 20px;
    border-radius: 30px;
    text-transform: uppercase;
    box-shadow: 0 5px #DD3E31;
}

.rappiButton:hover {
    background-color: #E24C3D;
    box-shadow: 0 5px #B03328;
    color: #E6E6E6;
}

@media(max-width: 1200px) {
    .optionsContainer {
        flex-direction: column;
        margin: 3rem 15rem 0 15rem !important;
    }

    .option {
        height: 25%;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

@media(max-width: 900px) {
    .optionsContainer {
        margin: 3rem 10rem 0 10rem !important;
    }
}

@media(max-width: 670px) {
    .optionsContainer {
        margin: 3rem 5rem 0 5rem !important;
    }
}

@media(max-width: 400px) {
    .optionsContainer {
        margin: 3rem 2rem 0 2rem !important;
    }
}