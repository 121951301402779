.container {
    background-color: #EF4423;
    font-family: Rockwell;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerContainer {

}

.mainLogo {
    width: 15rem;
}

.products {
    background-color: #FFFFFF;
    border: #631618 solid 10px;
    max-width: 800px !important;
}

.category {
    border-bottom: #EF4423 solid 10px;
}

.categoryTitle {
    color: #EF4423;
    font-size: xxx-large;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
}

.product {
    flex: 1 0 21%;
    margin-right: 2rem;
}

.prodTitle {
    color: #631618;
    font-weight: bold;
    font-size: xx-large;
    text-align: left;
}

.prodDesc {
    color: #631618;
    font-weight: bold;
    font-size: small;
    font-family: Mulish;
    text-align: left;
}

.priceText {
    color: #EF4423;
    font-weight: bold;
    font-size: xx-large;
    line-height: 1;
    text-align: left;
}

.noteText {
    color: #FFFFFF;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

@media(max-width: 992px) {
    .product {
        flex: 1 0 45%;
    }
}
